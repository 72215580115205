import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { DomainEventBus } from "./pubsub/DomainEventBus";
import { TaskModel } from "./domainModels/TaskModel";
import { FunctionalError } from "./errors/FunctionalError";
import { AddTaskEventModel } from "./events/AddTaskEventModel";
import { CompleteTaskEventModel } from "./events/CompleteTaskEventModel";
import { TaskMaybeEventModel } from "./events/TaskMaybeEventModel";
import { UpdateTaskEventModel } from "./events/UpdateTaskEventModel";
import { IReadOnlyRepository } from "./IReadonlyRepository";
import { TaskViewModel } from "./viewModels/TaskViewModel";
import { ProjectModel } from "./domainModels/ProjectModel";
import { AutoContextService } from "./AutoContextService";
import { ProjectViewModel } from "./viewModels/ProjectViewModel";
import { ProjectService } from "./ProjectService";
import { ArrayUtils } from "../util/ArrayUtils";
import { ModelCloner } from "../util/ModelCloner";

export type tasksGroupedByProjectType = {
  tasks: readonly TaskViewModel[];
  project?: ProjectViewModel;
};

export class TaskService {
  private projectService!: ProjectService;

  constructor(
    private readonly taskView: IReadOnlyRepository<TaskViewModel>, // InMemoryRepository
    //private readonly projectService: ProjectService,
    private readonly eventBus: DomainEventBus,
    private readonly autoContextService: AutoContextService,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public injectProjectService(projectService: ProjectService) {
    // Use method injection as a workaround for a circular dependency
    this.projectService = projectService;
  }

  public async add(taskModel: TaskModel): Promise<void> {
    const event = new AddTaskEventModel(taskModel);
    await this.eventBus.publishEvent(event);
  }

  public async updateTask(taskModel: TaskModel): Promise<void> {
    /* istanbul ignore else */ // At the moment impossible from integration
    if (await this.isExistingTask(taskModel.uuid)) {
      const event = new UpdateTaskEventModel(taskModel);
      await this.eventBus.publishEvent(event);
    } else {
      throw new FunctionalError("invalid task uuid");
    }
  }

  public async getTask(index: number): Promise<TaskViewModel> {
    const tasks = await this.getTasks();
    const task = tasks.find((t) => t.displayId === index);

    if (!task) {
      throw new FunctionalError("invalid task number");
    }

    return task;
  }

  public async getTasks(): Promise<readonly TaskViewModel[]> {
    return this.taskView.getAll();
  }

  public async getUncompletedTasks(
    project?: string,
  ): Promise<readonly TaskViewModel[]> {
    const tasks = await this.getTasks();
    return tasks.filter((t) => {
      return (
        !t.completed && (project === undefined || t.projects.includes(project))
      );
    });
  }

  public async getCurrentTasks(): Promise<readonly TaskViewModel[]> {
    const tasks = await this.getTasks();
    return tasks.filter((t) => {
      return !t.completed && !t.maybe;
    });
  }

  public async getMaybeTasks(): Promise<readonly TaskViewModel[]> {
    const tasks = await this.getTasks();
    return tasks.filter((t) => {
      return !t.completed && t.maybe;
    });
  }

  public async completeTask(index: number): Promise<void> {
    const task = await this.getTask(index);
    const event = new CompleteTaskEventModel(task.uuid);
    await this.eventBus.publishEvent(event);
  }

  public async updateProjectName(
    oldProjectName: string,
    newProjectName: string,
  ): Promise<void> {
    const projectTasks = await this.getByProject(oldProjectName);
    for (const task of projectTasks) {
      const replacedProjects = this.replaceProject(
        task.projects,
        oldProjectName,
        newProjectName,
      );

      const updatedTask = ModelCloner.updateValues(task, {
        projects: replacedProjects,
      });
      await this.updateTask(updatedTask);
    }
  }

  public async toggleMaybe(task: TaskModel): Promise<boolean> {
    const newMaybeState = !task.maybe;
    const event = new TaskMaybeEventModel(task.uuid, newMaybeState);
    await this.eventBus.publishEvent(event);
    return newMaybeState;
  }

  public async getByProject(projectName: string) {
    const tasks = await this.getTasks();
    return tasks.filter((t) => {
      return t.projects.includes(projectName);
    });
  }

  // TODO: Test
  public filterByContext(
    tasks: readonly TaskViewModel[],
    filterContext: string,
  ) {
    //const autoContext = await this.autoContextService.getContext();
    if (filterContext) {
      tasks = tasks.filter((t) => {
        return !!t.contexts.find((context) => {
          return (
            context.toLocaleLowerCase() === filterContext.toLocaleLowerCase()
          );
        });
      });
    }
    return tasks;
  }

  // TODO: Test
  public async filterByAutoContext(tasks: readonly TaskViewModel[]) {
    const autoContext = await this.autoContextService.getContext();
    return this.filterByContext(tasks, autoContext);
  }

  // TODO: Test
  public filterByProject(
    uncompletedTasks: readonly TaskViewModel[],
    project: ProjectModel,
  ): readonly TaskViewModel[] {
    return uncompletedTasks.filter((t) => {
      return t.projects.includes(project.project);
    });
  }

  // TODO: Duplication with printTasksByProject. Make that function use this.
  /* istanbul ignore next */ // TODO: Test
  public async groupTasksByProject(
    tasks: readonly TaskViewModel[],
  ): Promise<tasksGroupedByProjectType[]> {
    const result = [];
    const projects = await this.projectService.getNotCompleted();

    for (const project of projects) {
      const filteredTasks = this.filterByProject(tasks, project);
      tasks = ArrayUtils.difference(tasks, filteredTasks);
      if (filteredTasks.length > 0) {
        result.push({ tasks: filteredTasks, project: project });
      }
    }
    const remainingTasks = tasks;
    if (remainingTasks.length > 0) {
      result.push({ tasks: remainingTasks, project: undefined });
    }

    return result;
  }

  private replaceProject(
    projects: string[],
    oldProjectName: string,
    newProjectName: string,
  ): string[] {
    const index = projects.indexOf(oldProjectName); // should never happen
    /* istanbul ignore else */
    if (index !== -1) {
      projects[index] = newProjectName;
    } else {
      throw new Error("Old project name not found");
    }
    return projects;
  }

  private async isExistingTask(uuid: string | undefined): Promise<boolean> {
    const tasks = await this.getTasks();
    return !!tasks.find((t) => t.uuid === uuid);
  }
}
