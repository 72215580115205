import { IRepository } from "../domain/IRepository";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { timestamp } from "../domain/Types";
import { AbstractRepository } from "./AbstractRepository";
import { IValidate } from "../domain/domainModels/IValidate";
import { IIdentifiable } from "../domain/domainModels/IIdentifiable";

export class ValidatedRepository<T extends IIdentifiable & IValidate>
  extends AbstractRepository<T>
  implements IRepository<T>
{
  constructor(protected readonly repository: IRepository<T>) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
    super();
  }

  public async save(model: T): Promise<timestamp> {
    // Validate before we change the state
    model.validate();
    return this.repository.save(model);
  }

  public deleteAll(): Promise<timestamp> {
    return this.repository.deleteAll();
  }

  /* istanbul ignore next */ // Unused during integration
  public delete(uuid: string): Promise<timestamp> {
    return this.repository.delete(uuid);
  }

  public async getAll(): Promise<readonly T[]> {
    return this.repository.getAll();
  }

  /* istanbul ignore next */ // Unused during integration
  public override getByUuid(uuid: string): Promise<T> {
    return this.repository.getByUuid(uuid);
  }

  public override tryGetByUuid(uuid: string): Promise<T | undefined> {
    return this.repository.tryGetByUuid(uuid);
  }

  /* istanbul ignore next */ // Unused during integration
  public getTimestamp(): Promise<timestamp> {
    return this.repository.getTimestamp();
  }

  public override async import(models: readonly T[]): Promise<timestamp> {
    // Don't validate import as old immutable data goes through here and we validate explicitly after migration
    return await this.repository.import(models);
  }
}
