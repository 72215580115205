import { Component } from "react";

import "./TaskList.css";

import { AbstractCompositionRoot } from "brainsupporter-core/lib/domain/AbstractCompositionRoot";
import { AutoContextService } from "brainsupporter-core/lib/domain/AutoContextService";
import { Notifier } from "brainsupporter-core/lib/domain/pubsub/Notifier";
import { BotService } from "brainsupporter-core/lib/domain/bot/BotService";
import {
  TaskService,
  tasksGroupedByProjectType,
} from "brainsupporter-core/lib/domain/TaskService";
import Webconsole from "../Webconsole/Webconsole";
import { OpenLinksBotQuery } from "brainsupporter-core/lib/domain/bot/queries/OpenLinksBotQuery";
import { FormattingService } from "brainsupporter-core/lib/domain/bot/FormattingService";

class TaskListState {
  tasksGroupedByProjects: tasksGroupedByProjectType[] = [];
}

type TaskListProps = {
  root: AbstractCompositionRoot;
};

class TaskList extends Component<TaskListProps, TaskListState> {
  private static UNSUBSCRIBE_GROUP = "TaskList";

  root: AbstractCompositionRoot;
  taskService: TaskService;
  formattingService: FormattingService;
  botService: BotService;
  autoContextService: AutoContextService;
  notifier: Notifier;

  constructor(props: TaskListProps) {
    super(props);

    this.root = this.props.root;
    this.taskService = this.root.TaskService;
    this.formattingService = this.root.FormattingService;
    this.botService = this.props.root.BotService;
    this.autoContextService = this.props.root.AutoContextService;
    this.notifier = this.props.root.Notifier;

    this.state = new TaskListState();
  }

  override async componentDidMount() {
    this.notifier.subscribe(
      Webconsole.COMMAND_EXECUTED,
      /* istanbul ignore next */ // Coverage can be improved
      async () => {
        void this.updateTaskList();
      },
      TaskList.UNSUBSCRIBE_GROUP,
    );

    void this.updateTaskList();
  }

  override async componentWillUnmount() {
    this.notifier.unsubscribeGroup(TaskList.UNSUBSCRIBE_GROUP);
  }

  private async updateTaskList() {
    // TODO: Move this logic to a queryfacade and use from lt and lm command and from here
    const currentTasks = await this.taskService.getCurrentTasks();
    const autoContext = await this.autoContextService.getContext();
    const tasksInContext = this.taskService.filterByContext(
      currentTasks,
      autoContext,
    );

    const tasksGroupedByProjects =
      await this.taskService.groupTasksByProject(tasksInContext);

    this.setState({
      tasksGroupedByProjects: tasksGroupedByProjects,
    });
  }

  async openLink(event: React.SyntheticEvent, displayId: number) {
    event.preventDefault(); // prevent following link from html

    const command = OpenLinksBotQuery.staticCommandName + " " + displayId;
    void this.notifier.publish(Webconsole.EXECUTE_COMMAND, command);
  }

  override render() {
    const { tasksGroupedByProjects } = this.state;

    const projectList = tasksGroupedByProjects.map((group) => {
      const tasksList = group.tasks.map((task) => {
        // TODO: refactor to a react component for a task?
        const nrOfLinks = task.links.length;

        // TODO: Can I get rid of the span and remove tasktext duplication?
        let optionalLink;

        /* istanbul ignore else */ // TODO: Fix coverage for multiple links
        if (nrOfLinks === 0) {
          optionalLink = (
            <span>
              {task.displayId}. {task.task}
            </span>
          );
        } else if (nrOfLinks === 1) {
          optionalLink = (
            <span>
              {task.displayId}.&nbsp;
              <a
                href={task.links[0]}
                target="_blank"
                rel="noopener"
                onClick={async (event) => this.openLink(event, task.displayId)}
              >
                {task.task}
              </a>
            </span>
          );
        } else {
          optionalLink = (
            <span>
              {task.displayId}.&nbsp;
              <a
                href={task.links[0]}
                target="_blank"
                rel="noopener"
                onClick={async (event) => this.openLink(event, task.displayId)}
              >
                {task.task}
              </a>
            </span>
          );
        }

        return (
          <li key={task.uuid} className="task">
            {optionalLink}
          </li>
        );
      });

      if (group.project) {
        return (
          <ul key={group.project.uuid} id="projectList-array">
            <li key={group.project.uuid} className="project">
              {this.formattingService.formatProject(group.project)}
              <ul id="tasksList-array">{tasksList}</ul>
            </li>
          </ul>
        );
      } else {
        return (
          <ul key="nogroup" id="tasksList-array">
            {tasksList}
          </ul>
        );
      }
    });

    // TODO: Render warning about task without projects. As icons?
    return (
      <div>
        <hr />
        {projectList}
      </div>
    );
  }
}

export default TaskList;
