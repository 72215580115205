import { Component } from "react";

// TODO: Check styling on really long contexts
import "./ContextChooser.css";

import { AbstractCompositionRoot } from "brainsupporter-core/lib/domain/AbstractCompositionRoot";
import { BotService } from "brainsupporter-core/lib/domain/bot/BotService";
import { AutoContextService } from "brainsupporter-core/lib/domain/AutoContextService";
import Webconsole from "../Webconsole/Webconsole";
import { Notifier } from "brainsupporter-core/lib/domain/pubsub/Notifier";
import { SetContextBotCommand } from "brainsupporter-core/lib/domain/bot/commands/SetContextBotCommand";

// TODO: Sync context also in title so it is visible in tab. keep brainsupporter at the end.
// TODO: Bug: Sc in 1 tabblad wijzigt de state in een ander actief tab, maar niet de UI (titile, dropdown).
// Misschien moet het los van elkaar werken?

class ContextChooserState {
  currentContext = "";
  inputContext = "";
  contextList: string[] = [];
}

type ContextChooserProps = {
  root: AbstractCompositionRoot;
};

class ContextChooser extends Component<
  ContextChooserProps,
  ContextChooserState
> {
  private static UNSUBSCRIBE_GROUP = "ContextChooser";

  botService: BotService;
  autoContextService: AutoContextService;
  notifier: Notifier;

  constructor(props: ContextChooserProps) {
    super(props);

    this.botService = this.props.root.BotService;
    this.autoContextService = this.props.root.AutoContextService;
    this.notifier = this.props.root.Notifier;

    this.state = new ContextChooserState();
  }

  override async componentDidMount() {
    this.notifier.subscribe(
      Webconsole.COMMAND_EXECUTED,
      async (command) => {
        /* istanbul ignore else */ // Coverage can be improved
        if (command === SetContextBotCommand.staticCommandName) {
          const autoContext = await this.autoContextService.getContext();
          void this.updateContextUI(autoContext);
        }
      },
      ContextChooser.UNSUBSCRIBE_GROUP,
    );

    void this.refreshContextList();
    void this.initializeContext();
  }

  override async componentWillUnmount() {
    this.notifier.unsubscribeGroup(ContextChooser.UNSUBSCRIBE_GROUP);
  }

  private async refreshContextList() {
    const result = await this.botService.sendText("lc");
    const contextList = result.feedback.split("\n").filter((o) => !!o); // Split and filter empty lines
    contextList.unshift(" "); // Add empty option to remove context
    this.setState({ contextList: contextList });
  }

  private async initializeContext() {
    const urlParams = new URLSearchParams(window.location.search);
    const urlContext = urlParams.get("context");

    /* istanbul ignore if */ // Coverage can be improved
    if (urlContext) {
      await this.setContextCommand(urlContext);
    } else {
      const autoContext = await this.autoContextService.getContext();
      await this.setContextCommand(autoContext);
    }
  }

  private async updateContextUI(contextParam: string) {
    const context = this.normalizeContext(contextParam);
    this.updateContextInQueryParameter(context);
    this.setState({ currentContext: context });
  }

  /* istanbul ignore next */ // Coverage can be improved
  private updateContextInQueryParameter(context: string) {
    if (window.history.replaceState) {
      //prevents browser from storing history with each change:
      const url = new URL(location.href);
      if (context) {
        url.searchParams.set("context", context);
      } else {
        url.searchParams.delete("context");
      }
      // Replace url without reloading
      window.history.replaceState({}, "Brainsupporter", url);
    }
  }

  /* istanbul ignore next */ // Coverage can be improved
  private async setContextCommand(context: string) {
    let command;
    if (context === " ") {
      command = "sc";
    } else {
      command = "sc " + context;
    }
    await this.botService.sendText(command);
    // Updates the UI
    await this.notifier.publish(
      Webconsole.COMMAND_EXECUTED,
      SetContextBotCommand.staticCommandName,
    );
  }

  /* istanbul ignore next */ // Coverage can be improved
  private normalizeContext(context: string | null): string {
    if (!context) {
      context = "";
    }

    context = context.toLocaleLowerCase().trim();

    if (context.startsWith("@")) {
      context = context.substring(1);
    }

    return context;
  }

  /* istanbul ignore next */ // Coverage can be improved
  handleChange = async (event: React.FormEvent<HTMLInputElement>) => {
    const context = event.currentTarget.value;
    this.setState({ inputContext: context });
  };

  /* istanbul ignore next */ // Coverage can be improved
  handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault(); // Stop reload
    this.setState({ inputContext: "" });

    const newContext = this.normalizeContext(this.state.inputContext);
    await this.setContextCommand(newContext);

    Webconsole.focusToCommand();
  };

  /* istanbul ignore next */ // Coverage can be improved
  handleFocus = async () => {
    // Clear the input so there is no filtering
    this.setState({ inputContext: "" });
  };

  /* istanbul ignore next */ // Coverage can be improved
  handleMousedown = async () => {
    // Refresh before focus in order to avoid redraw
    await this.refreshContextList();
  };

  // LATER: Firefox behaves very different
  // LATER: Because of above and all the detailed implementation events, replace by https://react-select.com/ ?
  /* istanbul ignore next */ // Coverage can be improved
  handleSelect = async (event: React.SyntheticEvent) => {
    const list = this.state.contextList;
    const input = this.state.inputContext;

    let startsWith = 0;
    for (const item of list) {
      if (item.startsWith(input)) {
        startsWith++;
      }
    }

    let equals = 0;
    for (const item of list) {
      if (item === input) {
        equals++;
      }
    }

    // Don't submit if a longer match could work, let the user do it
    if (equals === 1 && startsWith === 1) {
      await this.handleSubmit(event);
    }
  };

  override render() {
    return (
      <form className="contextChooserForm" onSubmit={this.handleSubmit}>
        <label htmlFor="context">Context @{this.state.currentContext}</label>
        <input
          type="text"
          id="contextDataList"
          className="contextDataList"
          name="context"
          value={this.state.inputContext}
          onChange={this.handleChange}
          list="contextList"
          autoComplete="off"
          onFocus={this.handleFocus}
          onMouseDown={this.handleMousedown}
          onSelect={this.handleSelect}
        />
        <datalist id="contextList">
          {this.state.contextList.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </datalist>
      </form>
    );
  }
}

export default ContextChooser;
