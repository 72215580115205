import { TechnicalError } from "../domain/errors/TechnicalError";
import { BrowserOrNode } from "../util/BrowserOrNode";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { EnvironmentUtils } from "../util/EnvironmentUtils";

export type logSubscriber = (message: string) => Promise<void>;

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IConsoleLog {
  debug(message?: any, ...optionalParams: any[]): void;
  error(message?: any, ...optionalParams: any[]): void;
  info(message?: any, ...optionalParams: any[]): void;
  log(message?: any, ...optionalParams: any[]): void;
  warn(message?: any, ...optionalParams: any[]): void;
}

/* istanbul ignore next */ // No integration coverage on (error) logging
export class LogService {
  private logSubscribers: logSubscriber[] = [];
  protected logger: IConsoleLog = console;
  protected logMessageSuffix = "\n\n";

  // To access statically. This requires the class to be used as a singleton (one instance in de root)
  // Use as little as possible and only when DI is not feasable in static methods
  private static instance: LogService | undefined; // Could also be a subclass
  public static getInstance() {
    if (this.instance) {
      return this.instance;
    } else {
      throw new TechnicalError("LogService.current undefined");
    }
  }

  public static setInstance(instance: LogService | undefined) {
    this.instance = instance;
  }

  constructor() {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async logInfoMessage(infoMessage: string): Promise<void> {
    const enrichedMessage = this.enrichMessage(infoMessage, "info");
    this.logger.info(enrichedMessage);

    for (const sub of this.logSubscribers) {
      await sub(enrichedMessage);
    }
  }

  public async logWarning(error: Error): Promise<void> {
    this.logger.warn(error);
    const message = this.getErrorMessage(error);
    await this.logWarningMessage(message);
  }

  public async logWarningMessage(warningMessage: string): Promise<void> {
    const enrichedMessage = this.enrichMessage(warningMessage, "warning");
    this.logger.warn(enrichedMessage);

    for (const sub of this.logSubscribers) {
      await sub(enrichedMessage);
    }
  }

  public async logError(error: Error): Promise<void> {
    this.logger.error(error); // Maybe we log double now, but the error might contain more than the message?
    const message = this.getErrorMessage(error);
    await this.logErrorMessage(message);
  }

  public async logErrorMessage(errorMessage: string): Promise<void> {
    const enrichedMessage = this.enrichMessage(errorMessage, "error");
    this.logger.error(enrichedMessage);

    for (const sub of this.logSubscribers) {
      await sub(enrichedMessage);
    }
  }

  public subscribeLogs(sub: logSubscriber): void {
    this.logSubscribers.push(sub);
  }

  private getErrorMessage(error: Error) {
    let message = error.toString();
    message += error.stack ? error.stack : "";

    if ((error as TechnicalError).additionalDetails) {
      message += "\n  " + (error as TechnicalError).additionalDetails;
    }
    return message;
  }

  private enrichMessage(message: string, level: string): string {
    const date = new Date().toLocaleString();
    const version = EnvironmentUtils.getFullVersion();
    const environment = EnvironmentUtils.getEnvironment();
    return `${level.toUpperCase()} - ${date} - ${version} - ${environment}: ${message}${this.logMessageSuffix}`;
  }

  private static registeredUnhandledPromises = false;

  /* istanbul ignore next */ // Hard to test
  public static processUnhandledPromises() {
    if (this.registeredUnhandledPromises) {
      return;
    }

    if (BrowserOrNode.isBrowser() || BrowserOrNode.isWebWorker()) {
      window.onunhandledrejection = async (event) => {
        const error = new TechnicalError(
          "Unhandled promise rejection: " + event.reason,
        );
        await LogService.getInstance().logError(error);
      };
    }

    if (BrowserOrNode.isNode()) {
      process.on("unhandledRejection", async (error: any) => {
        await LogService.getInstance().logErrorMessage(
          "Unhandled promise rejection: ",
        );
        await LogService.getInstance().logError(error);
      });
    }
    this.registeredUnhandledPromises = true;
  }
}
