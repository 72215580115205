import * as Uuid from "uuid";

import { AutoContextService } from "../domain/AutoContextService";
import { TaskModel } from "../domain/domainModels/TaskModel";
import { IRepository } from "../domain/IRepository";
import { timestamp } from "../domain/Types";
import { DateTimeUtils } from "../util/DateTimeUtils";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { FileSystemUtils } from "../util/FileSystemUtils";
import { IFileHelper } from "../util/IFileHelper";
import { TextUtils } from "../util/TextUtils";
import { AbstractRepository } from "./AbstractRepository";
import { ModelCloner } from "../util/ModelCloner";

export class TodoTxtRepository
  extends AbstractRepository<TaskModel>
  implements IRepository<TaskModel>
{
  // Specifications at https://github.com/todotxt/todo.txt

  constructor(private readonly fileHelper: IFileHelper) {
    super();

    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  // Not used in integration as we only use this for import export
  /* istanbul ignore next */
  public async getAll(): Promise<readonly TaskModel[]> {
    let todoTxtContent;
    try {
      todoTxtContent = await this.fileHelper.readFile();
    } catch (error) {
      FileSystemUtils.validateIsNoSuchFileOrDirectoryError(error as Error);
      return [];
    }
    const tasksLines = todoTxtContent.split(/\r?\n/);

    return tasksLines
      .filter((t) => {
        return t !== "";
      })
      .map((tl) => {
        return this.parseTaskLine(tl);
      });
  }

  // Not used in integration as we only use this for import export
  /* istanbul ignore next */
  public async save(taskModel: TaskModel): Promise<timestamp> {
    const taskModels = await this.getAll();
    const mutableTaskModels = taskModels.slice();

    let replaced = false;
    for (let i = 0; i < mutableTaskModels.length; i++) {
      if (taskModel.uuid === mutableTaskModels[i].uuid) {
        mutableTaskModels[i] = taskModel;
        replaced = true;
      }
    }

    if (replaced) {
      await this.saveAllTasks(mutableTaskModels);
    } else {
      const displayId = mutableTaskModels.length + 1;
      await this.add(taskModel, displayId);
    }

    return DateTimeUtils.zeroTimestamp; // No timestamp implemented
  }

  private async add(
    taskModel: TaskModel,
    displayId: number,
  ): Promise<timestamp> {
    const todoTxtLine = this.generateTodoTxtLine(taskModel, displayId);
    await this.fileHelper.appendFile(todoTxtLine);
    return DateTimeUtils.zeroTimestamp; // No timestamp implemented
  }

  // Not used in integration as we only use this for import export
  /* istanbul ignore next */
  public async deleteAll(): Promise<timestamp> {
    await this.fileHelper.saveTextFile("");
    return DateTimeUtils.zeroTimestamp; // No timestamp implemented
  }

  /* istanbul ignore next */ // Not used during integration
  public async getTimestamp(): Promise<timestamp> {
    return DateTimeUtils.zeroTimestamp; // No timestamp implemented
  }

  /* istanbul ignore next */
  public async delete(uuid: string): Promise<timestamp> {
    const models = await this.getAll();
    const mutableModels = models.slice();

    const index = this.findIndex(mutableModels, uuid);
    mutableModels.splice(index, 1);
    await this.saveAllTasks(mutableModels);
    return DateTimeUtils.zeroTimestamp; // No timestamp implemented  }
  }

  // Not used in integration as we only use this for import export
  /* istanbul ignore next */
  private findIndex(models: readonly TaskModel[], uuid: string) {
    return models.findIndex((m: TaskModel) => {
      return m.uuid === uuid;
    });
  }

  private parseTaskLine(line: string): TaskModel {
    const completed = line.startsWith("x ") || line.startsWith("X ");
    let remainder = line;

    if (completed) {
      remainder = line.substring(2);
    }

    const allTokens =
      /((\s|^)\+|(\s|^)@|\slink:|\stag:|\smaybe:)|\sid:|\suuid:|\screationDateTime:|\scompletionDateTime:/;

    const task = TextUtils.extractTextFragmentUntilAnyToken(
      remainder,
      allTokens,
    );
    const projects = TextUtils.extractMultipleTextFragmentsWithoutTheirTokens(
      remainder,
      /(\s|^)\+/,
      allTokens,
    );
    const contexts = this.parseContext(remainder, allTokens);

    let uuid = TextUtils.extractSingleTextFragmentWithoutItsToken(
      remainder,
      /\suuid:/,
      allTokens,
    );
    const links = TextUtils.extractMultipleTextFragmentsWithoutTheirTokens(
      remainder,
      /\slink:/,
      allTokens,
    );
    const tags = TextUtils.extractMultipleTextFragmentsWithoutTheirTokens(
      remainder,
      /\stag:/,
      allTokens,
    );
    const maybeText = TextUtils.extractSingleTextFragmentWithoutItsToken(
      remainder,
      /\smaybe:/,
      allTokens,
    );
    const maybe = maybeText.toLowerCase() === "true";
    const creationDateTimeText =
      TextUtils.extractSingleTextFragmentWithoutItsToken(
        remainder,
        /\screationDateTime:/,
        allTokens,
      );
    const completionDateTimeText =
      TextUtils.extractSingleTextFragmentWithoutItsToken(
        remainder,
        /\scompletionDateTime:/,
        allTokens,
      );

    if (!uuid) {
      uuid = Uuid.v4();
    }

    let taskModel = new TaskModel({
      uuid,
      task,
      projects,
      contexts,
      completed,
      links,
      maybe,
      tags,
    });

    const creationDateTime = parseInt(creationDateTimeText, 10);
    if (creationDateTime) {
      // At the moment we don't read or specify the creation date in todo.txt
      // (4th field in todo.txt, without the time)
      taskModel = ModelCloner.updateValues(taskModel, { creationDateTime });
    }

    const completionDateTime = parseInt(completionDateTimeText, 10);
    if (completionDateTime) {
      // At the moment we don't read or specify the completion date in todo.txt
      // (3th field in todo.txt, without the time)
      taskModel = ModelCloner.updateValues(taskModel, { completionDateTime });
    }

    return taskModel;
  }

  private parseContext(remainder: string, allTokens: RegExp) {
    const contexts = TextUtils.extractMultipleTextFragmentsWithoutTheirTokens(
      remainder,
      /(\s|^)@/,
      allTokens,
    );

    return contexts.map((context) => {
      return AutoContextService.formatContext(context);
    });
  }

  private generateTodoTxtLine(taskModel: TaskModel, displayId: number): string {
    let todoTxtLine = "";

    if (taskModel.completed) {
      todoTxtLine += "x ";
    }

    todoTxtLine += taskModel.task;

    for (const project of taskModel.projects) {
      todoTxtLine += " +" + project;
    }

    for (const context of taskModel.contexts) {
      todoTxtLine += " @" + context;
    }

    todoTxtLine += " id:" + displayId;
    todoTxtLine += " uuid:" + taskModel.uuid;

    for (const link of taskModel.links) {
      todoTxtLine += " link:" + link;
    }

    for (const tag of taskModel.tags) {
      todoTxtLine += " tag:" + tag;
    }

    if (taskModel.maybe) {
      todoTxtLine += " maybe:true";
    }

    if (taskModel.creationDateTime) {
      todoTxtLine += " creationDateTime:" + taskModel.creationDateTime;
    }

    if (taskModel.completionDateTime) {
      todoTxtLine += " completionDateTime:" + taskModel.completionDateTime;
    }

    todoTxtLine += "\n";

    return todoTxtLine;
  }

  // Not used in integration as we only use this for import export
  /* istanbul ignore next */
  private async saveAllTasks(taskModels: readonly TaskModel[]): Promise<void> {
    let taskText = "";
    for (const [id, taskModel] of taskModels.entries()) {
      taskText += this.generateTodoTxtLine(taskModel, id);
    }
    await this.fileHelper.saveTextFile(taskText);
  }
}
