import { ConfigurationManager } from "../config/ConfigurationManager";
import { LogService } from "../logging/LogService";
import { AutoContextService } from "./AutoContextService";
import { BotService } from "./bot/BotService";
import { AddBotCommand } from "./bot/commands/AddBotCommand";
import { CompleteProjectBotCommand } from "./bot/commands/CompleteProjectBotCommand";
import { CompleteTaskBotCommand } from "./bot/commands/CompleteTaskBotCommand";
import { ImportBotCommand } from "./bot/commands/ImportBotCommand";
import { MaybeBotCommand } from "./bot/commands/MaybeBotCommand";
import { SetContextBotCommand } from "./bot/commands/SetContextBotCommand";
import { UpdateProjectBotCommand } from "./bot/commands/UpdateProjectBotCommand";
import { UpdateTaskBotCommand } from "./bot/commands/UpdateTaskBotCommand";
import { ExportBotQuery } from "./bot/queries/ExportBotQuery";
import { ListProjectsBotQuery } from "./bot/queries/ListProjectsBotQuery";
import { ListTasksBotQuery } from "./bot/queries/ListTasksBotQuery";
import { OpenLinksBotQuery } from "./bot/queries/OpenLinksBotQuery";
import { VersionBotQuery } from "./bot/queries/VersionBotQuery";
import { ContextService } from "./ContextService";
import { DomainEventBus } from "./pubsub/DomainEventBus";
import { EncryptionService } from "./EncryptionService";
import { DomainEventModel } from "./events/DomainEventModel";
import { EventStore } from "./EventStore";
import { InMemoryRepository } from "./InMemoryRepository";
import { IRepository } from "./IRepository";
import { SingleRepository } from "../repository/SingleRepository";
import { ProjectService } from "./ProjectService";
import { SmartCaptureService } from "./SmartCapture/SmartCaptureService";
import { SmartCaptureWebParser } from "./SmartCapture/SmartCaptureWebParser";
import { SmartGenerator } from "./SmartCapture/SmartGenerator";
import { TaskService } from "./TaskService";
import { ProjectViewGenerator } from "./viewGenerators/ProjectViewGenerator";
import { TaskViewGenerator } from "./viewGenerators/TaskViewGenerator";
import { ProjectViewModel } from "./viewModels/ProjectViewModel";
import { TaskViewModel } from "./viewModels/TaskViewModel"; // No need to test AbstractCompositionRoot
import { IEncryptedModel } from "./domainModels/IEncryptedModel";
import { UserModel } from "./domainModels/UserModel";
import { ContextModel } from "./domainModels/ContextModel";
import { bcryptHash, bcryptGenSalt } from "./Types";
import { RestUtils } from "../util/RestUtils";
import { FormattingService } from "./bot/FormattingService";
import { ListMaybeBotQuery } from "./bot/queries/ListMaybeBotQuery";
import { EncryptionPasswordService } from "./EncryptionPasswordService";
import { UserService } from "./UserService";
import { InMemorySecureCache } from "../util/InMemorySecureCache";
import { ChangeEncryptionPasswordBotCommand } from "./bot/commands/ChangeEncryptionPasswordBotCommand";
import { HelpBotQuery } from "./bot/queries/HelpBotQuery";
import { ListContextBotQuery } from "./bot/queries/ListContextBotQuery";
import { AutoCompleteService } from "./AutoCompleteService";
import { Notifier } from "./pubsub/Notifier";
import { SessionTokenModel } from "./domainModels/SessionTokenModel";

/* eslint-disable @typescript-eslint/no-explicit-any */
export class AbstractCompositionRoot {
  protected static staticallyInitialized = false;

  public BcryptHash!: bcryptHash;
  public BcryptGenSalt!: bcryptGenSalt;
  public Cheerio!: cheerio.CheerioAPI;
  public Open!: any;
  public FetchFn: any;

  public ConfigurationManager!: ConfigurationManager;
  public DomainEventRepository!: IRepository<DomainEventModel>;
  public EncryptedDomainEventRepository!: IRepository<IEncryptedModel>;
  public UserRepository!: SingleRepository<UserModel>;
  public SessionTokenRepository!: IRepository<SessionTokenModel>;
  public ContextRepository!: IRepository<ContextModel>;
  public RestUtils!: RestUtils;

  public EventBus!: DomainEventBus;
  public EventStore!: EventStore;
  public Notifier!: Notifier;

  public TaskView!: InMemoryRepository<TaskViewModel>;
  public ProjectView!: InMemoryRepository<ProjectViewModel>;

  public TaskViewGenerator!: TaskViewGenerator;
  public ProjectViewGenerator!: ProjectViewGenerator;

  public AutoContextService!: AutoContextService;
  public TaskService!: TaskService;
  public ProjectService!: ProjectService;
  public ContextService!: ContextService;
  public LogService!: LogService;
  public SmartCaptureService!: SmartCaptureService;
  public SmartCaptureWebParser!: SmartCaptureWebParser;
  public AutoCompleteService!: AutoCompleteService;

  public AddBotCommand!: AddBotCommand;
  public ChangeEncryptionPasswordBotCommand!: ChangeEncryptionPasswordBotCommand;
  public ExportBotQuery!: ExportBotQuery;
  public ImportBotCommand!: ImportBotCommand;
  public ListContextBotQuery!: ListContextBotQuery;
  public ListProjectsBotQuery!: ListProjectsBotQuery;
  public ListTasksBotQuery!: ListTasksBotQuery;
  public ListMaybeBotQuery!: ListMaybeBotQuery;
  public UpdateTaskBotCommand!: UpdateTaskBotCommand;
  public SetContextBotCommand!: SetContextBotCommand;
  public VersionQuery!: VersionBotQuery;
  public CompleteProjectBotCommand!: CompleteProjectBotCommand;
  public CompleteTaskBotCommand!: CompleteTaskBotCommand;
  public UpdateProjectBotCommand!: UpdateProjectBotCommand;
  public MaybeBotCommand!: MaybeBotCommand;
  public OpenLinksBotQuery!: OpenLinksBotQuery;
  public HelpQuery!: HelpBotQuery;

  public BotService!: BotService;
  public FormattingService!: FormattingService;

  public EncryptionService!: EncryptionService;
  public EncryptionPasswordService!: EncryptionPasswordService;
  public UserService!: UserService;
  public SmartGenerator!: SmartGenerator;

  public EncryptionKeySecureCache!: InMemorySecureCache;

  constructor() {
    this.setConfigurableDependencies();
    this.setLateDependencies();
    this.initialize();
    this.setStaticInitializers();
  }

  protected setStaticInitializers(): void {
    if (!AbstractCompositionRoot.staticallyInitialized) {
      AbstractCompositionRoot.staticallyInitialized = true;

      LogService.setInstance(this.LogService);
      LogService.processUnhandledPromises();
    }
  }

  /* istanbul ignore next */
  protected setConfigurableDependencies(): void {
    throw new Error("Implement in derived class");
  }

  protected initialize(): void {
    // Override if needed.
  }

  protected setLateDependencies(): void {
    /* pub sub */
    this.EventStore = new EventStore(this.EventBus, this.DomainEventRepository);
    this.Notifier = new Notifier();

    /* views */
    this.TaskView = new InMemoryRepository<TaskViewModel>();
    this.ProjectView = new InMemoryRepository<ProjectViewModel>();

    /* services */
    this.UserService = new UserService(
      this.UserRepository,
      this.EncryptionPasswordService,
    );

    this.AutoContextService = new AutoContextService(this.ContextRepository);
    this.TaskViewGenerator = new TaskViewGenerator(
      this.TaskView,
      this.EventBus,
    );
    this.TaskService = new TaskService(
      this.TaskView,
      this.EventBus,
      this.AutoContextService,
    );
    this.ProjectService = new ProjectService(
      this.TaskService,
      this.EventBus,
      this.ProjectView,
    );
    this.ContextService = new ContextService(this.TaskService);
    this.SmartCaptureService = new SmartCaptureService(
      this.AutoContextService,
      this.ProjectService,
      this.SmartCaptureWebParser,
      this.TaskService,
    );
    this.FormattingService = new FormattingService(
      this.TaskService,
      this.ProjectService,
      this.AutoContextService,
    );
    this.ProjectViewGenerator = new ProjectViewGenerator(
      this.ProjectView,
      this.EventBus,
      this.ProjectService,
    );
    this.SmartGenerator = new SmartGenerator(
      this.TaskService,
      this.ProjectService,
    );
    this.AutoCompleteService = new AutoCompleteService(
      this.ProjectService,
      this.ContextService,
    );

    /* queries */
    this.HelpQuery = new HelpBotQuery();
    this.ListContextBotQuery = new ListContextBotQuery(this.ContextService);
    this.ListProjectsBotQuery = new ListProjectsBotQuery(this.ProjectService);
    this.ListTasksBotQuery = new ListTasksBotQuery(
      this.FormattingService,
      this.TaskService,
    );
    this.ListMaybeBotQuery = new ListMaybeBotQuery(
      this.FormattingService,
      this.TaskService,
    );
    this.ExportBotQuery = new ExportBotQuery(
      this.DomainEventRepository,
      this.TaskView,
      this.ProjectView,
    );
    this.OpenLinksBotQuery = new OpenLinksBotQuery(this.TaskService, this.Open);
    this.VersionQuery = new VersionBotQuery();

    /* commands */
    this.AddBotCommand = new AddBotCommand(
      this.SmartCaptureService,
      this.AutoCompleteService,
    );
    this.ChangeEncryptionPasswordBotCommand =
      new ChangeEncryptionPasswordBotCommand(
        this.EncryptionPasswordService,
        this.ExportBotQuery,
      );
    this.ImportBotCommand = new ImportBotCommand(
      this.TaskView,
      this.DomainEventRepository,
      this.EncryptionPasswordService,
      this.ProjectView,
      this.TaskService,
      this.ProjectService,
      this.EventStore,
    );
    this.UpdateTaskBotCommand = new UpdateTaskBotCommand(
      this.AutoContextService,
      this.TaskService,
      this.ProjectService,
      this.SmartGenerator,
      this.AutoCompleteService,
    );
    this.UpdateProjectBotCommand = new UpdateProjectBotCommand(
      this.AutoCompleteService,
      this.ProjectService,
      this.TaskService,
      this.SmartGenerator,
    );
    this.SetContextBotCommand = new SetContextBotCommand(
      this.AutoCompleteService,
      this.AutoContextService,
      this.ContextService,
    );
    this.CompleteProjectBotCommand = new CompleteProjectBotCommand(
      this.ProjectService,
    );
    this.CompleteTaskBotCommand = new CompleteTaskBotCommand(this.TaskService);
    this.MaybeBotCommand = new MaybeBotCommand(this.TaskService);

    /* botservice */
    this.BotService = new BotService(
      this.AddBotCommand,
      this.ChangeEncryptionPasswordBotCommand,
      this.CompleteProjectBotCommand,
      this.CompleteTaskBotCommand,
      this.MaybeBotCommand,
      this.ExportBotQuery,
      this.ImportBotCommand,
      this.ListContextBotQuery,
      this.ListMaybeBotQuery,
      this.ListProjectsBotQuery,
      this.ListTasksBotQuery,
      this.OpenLinksBotQuery,
      this.SetContextBotCommand,
      this.UpdateProjectBotCommand,
      this.UpdateTaskBotCommand,
      this.VersionQuery,
      this.HelpQuery,

      this.ConfigurationManager,
      this.LogService,
    );
  }
}
