import * as Uuid from "uuid";

import { IDomainModel } from "./IDomainModel";
import { timestamp } from "../Types";
import { Validate } from "../validators/Validate";

export class UserModel implements IDomainModel {
  // Carefull. This model is not encrypted serverside. Consider putting new fields in a different repository
  public readonly uuid: string = Uuid.v4();
  public readonly modelVersion = 1;
  public readonly __type: string = "UserModel";

  public readonly email: string = "";

  public readonly userPasswordEncryptionKeySalt: string = "";
  public readonly encryptedDataEncryptionKey: string = "";
  public readonly encryptedDataEncryptionKeyTimestamp: timestamp | null = null;
  public readonly encryptedPasswordVerificationPhrase: string = "";

  protected static expectedProperties = [
    "modelVersion",
    "__type",
    "uuid",
    "email",
    "userPasswordEncryptionKeySalt",
    "encryptedDataEncryptionKey",
    "encryptedDataEncryptionKeyTimestamp",
    "encryptedPasswordVerificationPhrase",
  ];

  constructor(init?: Partial<UserModel>) {
    Object.assign(this, init);
  }

  protected validateExpectedProperties() {
    Validate.expectedProperties(this, UserModel.expectedProperties);
  }

  public validate() {
    this.validateExpectedProperties();
    UserModel.validateModel(this);
  }

  public static validateModel(model: UserModel) {
    Validate.Uuidv4(model.uuid);
    Validate.number(model.modelVersion);
    Validate.stringNotEmpty(model.__type);
    Validate.string(model.email);
    Validate.string(model.userPasswordEncryptionKeySalt);
    Validate.string(model.encryptedDataEncryptionKey);
    //Validate.optionalTimestamp(model.encryptedDataEncryptionKeyTimestamp); // TODO: Implement
    Validate.string(model.encryptedPasswordVerificationPhrase);
  }
}
