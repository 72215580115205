import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { DomainEventBus } from "./pubsub/DomainEventBus";
import { DomainEventModel } from "./events/DomainEventModel";
import { IRepository } from "./IRepository";

export class EventStore {
  constructor(
    private eventBus: DomainEventBus,
    private eventRepo: IRepository<DomainEventModel>,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async eventHandler(event: DomainEventModel): Promise<void> {
    await this.eventRepo.save(event);
  }

  public async replayEventsAndSubscribe(): Promise<void> {
    // Carefull. Race condition possible when called successively becasue of awaits between clearing and setting the subscriber.
    this.eventBus.wildcardRemoveAllSubscribers();

    const allEvents = await this.eventRepo.getAll();
    const sortableEvents = allEvents.slice();

    sortableEvents.sort((event1, event2) => {
      return event1.sequenceNumber - event2.sequenceNumber;
    });

    this.eventBus.disableSubscriberCheck();
    for (const event of sortableEvents) {
      await this.eventBus.publishEvent(event);
    }
    this.eventBus.enableSubscriberCheck();

    this.startSavingEvents();
  }

  public startSavingEvents(): void {
    this.eventBus.wildcardSubscribe((e) =>
      this.eventHandler(e as DomainEventModel),
    );
  }
}
