import { IIdentifiable } from "../domain/domainModels/IIdentifiable";
import { IRepository } from "../domain/IRepository";
import { timestamp } from "../domain/Types";
import { DateTimeUtils } from "../util/DateTimeUtils";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { AbstractRepository } from "./AbstractRepository";

export class CachedRepository<T extends IIdentifiable>
  extends AbstractRepository<T>
  implements IRepository<T>
{
  private cacheModels: { [uuid: string]: T } = {};
  private lastTimestamp: timestamp = DateTimeUtils.zeroTimestamp;

  constructor(private readonly repository: IRepository<T>) {
    super();

    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async save(model: T): Promise<timestamp> {
    await this.updateTimeStamp(() => this.repository.save(model));

    this.cacheModels[model.uuid] = model;

    return this.lastTimestamp;
  }

  public async getAll(): Promise<readonly T[]> {
    /* istanbul ignore else */ // We don't invalidate cache during integration as we only add data in azure
    if (await this.isInvalidTimestamp()) {
      this.cacheModels = {};
      const models = await this.repository.getAll();
      this.updateCache(models);
      this.lastTimestamp = await this.repository.getTimestamp();
    }
    return Object.values(this.cacheModels);
  }

  /* istanbul ignore next */ //Not used during integration
  public override async tryGetByUuid(uuid: string): Promise<T | undefined> {
    if (await this.isInvalidTimestamp()) {
      return this.repository.tryGetByUuid(uuid);
    }
    return this.cacheModels[uuid];
  }

  public async deleteAll(): Promise<timestamp> {
    await this.repository.deleteAll();
    this.cacheModels = {};
    this.lastTimestamp = DateTimeUtils.zeroTimestamp;
    return this.lastTimestamp;
  }

  // only implemented for interface
  /* istanbul ignore next */
  public async getTimestamp(): Promise<timestamp> {
    return this.repository.getTimestamp();
  }

  // hard to test during integration
  /* istanbul ignore next */
  public async delete(uuid: string): Promise<timestamp> {
    await this.updateTimeStamp(() => this.repository.delete(uuid));

    delete this.cacheModels[uuid];
    return this.lastTimestamp;
  }

  public override async import(models: readonly T[]): Promise<timestamp> {
    this.cacheModels = {};
    const timestamp = this.repository.import(models);
    this.updateCache(models);
    return timestamp;
  }

  private async updateTimeStamp(repoAction: () => Promise<timestamp>) {
    const isInvalidTimestamp = await this.isInvalidTimestamp();
    const newTimestamp = await repoAction(); // We don't invalidate cache during integration as we only add data in azure
    /* istanbul ignore next */
    this.lastTimestamp = isInvalidTimestamp
      ? DateTimeUtils.zeroTimestamp
      : newTimestamp;
  }

  private async isInvalidTimestamp(): Promise<boolean> {
    const repoTimestamp = await this.repository.getTimestamp(); // We don't invalidate cache during integration as we only add data in azure
    // repoTimestamp is a number when the data is stored on azure as that code runs on node.
    /* istanbul ignore next */
    return (
      repoTimestamp === DateTimeUtils.zeroTimestamp ||
      //this.lastTimestamp === DateTimeUtils.zeroTimestamp ||
      this.lastTimestamp !== repoTimestamp
    );
  }

  private updateCache(modelArray: readonly T[]) {
    for (const model of modelArray) {
      this.cacheModels[model.uuid] = model;
    }
  }
}
