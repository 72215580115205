import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { ContextModel } from "./domainModels/ContextModel";
import { IRepository } from "./IRepository";

export class AutoContextService {
  public static formatContext(context: string): string {
    return context.replace("@", "").toLocaleLowerCase();
  }

  constructor(private readonly contextRepository: IRepository<ContextModel>) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async getContext(): Promise<string> {
    return (await this.getContextModel()).context;
  }

  public async setContext(context: string): Promise<string> {
    const currentContext = await this.getContextModel();
    const formattedContext = AutoContextService.formatContext(context);

    await this.contextRepository.updateValues(currentContext, {
      context: formattedContext,
    });

    return formattedContext;
  }

  public async applyAutoContext(contexts: string[]): Promise<string[]> {
    const currentContext = await this.getContext();
    if (contexts.length === 0 && currentContext) {
      return Promise.resolve([currentContext]);
    }
    return contexts;
  }

  private async getContextModel(): Promise<ContextModel> {
    const currentContextList = await this.contextRepository.getAll();
    let currentContext = currentContextList[0];

    if (!currentContext || !currentContext.uuid) {
      currentContext = new ContextModel();
    }
    return currentContext;
  }
}
