import { LogService } from "brainsupporter-core/lib/logging/LogService";

import { appInsights } from "./ApplicationInsightsService";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { EnvironmentUtils } from "brainsupporter-core/lib/util/EnvironmentUtils";

/* istanbul ignore next */ // Coverage can be improved
export class ApplicationInsightsLogService extends LogService {
  private appInsightsEnabled = !EnvironmentUtils.isProduction();
  protected override logMessageSuffix = "";

  public override async logInfoMessage(infoMessage: string): Promise<void> {
    if (this.appInsightsEnabled) {
      appInsights.trackTrace({
        message: infoMessage,
        severityLevel: SeverityLevel.Information,
      });
    }
    await super.logInfoMessage(infoMessage);
  }

  public override async logWarning(error: Error): Promise<void> {
    if (this.appInsightsEnabled) {
      appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Warning,
      });
    }
    await super.logWarning(error);
  }

  public override async logWarningMessage(
    warningMessage: string,
  ): Promise<void> {
    if (this.appInsightsEnabled) {
      appInsights.trackTrace({
        message: warningMessage,
        severityLevel: SeverityLevel.Warning,
      });
    }

    await super.logWarningMessage(warningMessage);
  }

  public override async logError(error: Error): Promise<void> {
    if (this.appInsightsEnabled) {
      appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
    await super.logError(error);
  }

  public override async logErrorMessage(errorMessage: string): Promise<void> {
    if (this.appInsightsEnabled) {
      appInsights.trackTrace({
        message: errorMessage,
        severityLevel: SeverityLevel.Error,
      });
    }

    await super.logErrorMessage(errorMessage);
  }
}
